import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

import TopBanner from '../../../components/Banners/TopBanner/TopBanner'
import Header from '../../../components/Header/Header'
import Footer from '../../../components/Footer/Footer'
import SubLeader from '../../../components/SubLeader/SubLeader'
import WeekendEscapesLauncher from '../../../components/organisms/weekendEscapesLauncher/WeekendEscapesLauncher'
import BlockResults from '../../../components/BlockResults/BlocksResults'
// eslint-disable-next-line node/no-extraneous-import
import { useQuery } from '@apollo/client'
import PageMeta from '../../../components/PageMeta/PageMeta'
// import { NationalWeekendEscape } from '../../../types'
import {
  WeekendEscapesResultsQuery,
  type IWeekendEscapesSearchQueryData,
  type IWeekendEscapesResultsQueryData
} from '../../../graphql/queries/WeekendEscapesSearchQuery'
import Leaderboard from '../../../components/Banners/Leaderboard/Leaderboard'

export const query = graphql`
  query {
    wpgraphql {
      adStarCodes {
        id
        adslot
      }
      generalSettings {
        title
        siteId
        egSettingTwitter
      }
      promoBoxesGroupSettings {
        promoBoxes
      }
      lBBCategory(id: "experiences", idType: SLUG) {
        description
        name
      }
    }
  }
`

interface WeekendEscapesSearchProps {
  data: IWeekendEscapesSearchQueryData
  category: string
}

const WeekendEscapesSearch = ({
  data: {
    wpgraphql: {
      generalSettings,
      lBBCategory,
      promoBoxesGroupSettings,
      adStarCodes
    }
  },
  category
}: WeekendEscapesSearchProps) => {
  const { siteId, title } = generalSettings
  const [searchTerm, setSearchTerm] = useState<string | null>(null)
  /* Get custom category name (all others are static) */
  const getawayCategory = lBBCategory
  const [featuredEscapes, setFeaturedEscapes] = useState<any | null>(null)

  const ESCAPE_CATEGORIES: Record<string, string> = {
    coastal: 'Coastal',
    'day-trip': 'Day Trip',
    'pet-friendly': 'Dog Friendly',
    spas: 'Spas',
    countryside: 'Countryside',
    getaways: getawayCategory?.name ?? 'Getaways',
    family: 'Family',
    waterside: 'Waterside',
    'special-occasion': 'Special Occasions',
    foodie: 'Foodie',
    city: 'City',
    romantic: 'Romantic',
    experiences: 'Experiences',
    budget: 'Budget',
    'beach-retreat': 'Beach Retreat',
    'cosy-cornwall': 'Cosy Cornwall',
    'design-led': 'Design Led',
    'hampshire-boltholes': 'Hampshire Boltholes',
    'happy-kids': 'Happy Kids',
    'lo-fi': 'Lo Fi',
    'norfolk-hideaways': 'Norfolk Hideaways',
    'featured-escapes': featuredEscapes?.box_1_page_title ?? 'Featured Escapes',
    'day-schools': 'Day Schools'
  }

  useEffect(() => {
    try {
      const json = JSON.parse(promoBoxesGroupSettings.promoBoxes)
      setFeaturedEscapes(json)
    } catch {
      setFeaturedEscapes(null)
    }
  }, [promoBoxesGroupSettings])

  const handleSearchChange = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  const {
    data: escapes,
    loading,
    error
  } = useQuery<IWeekendEscapesResultsQueryData>(WeekendEscapesResultsQuery, {
    variables: { category }
  })

  return (
    <>
      <PageMeta
        pageData={{
          ...generalSettings,
          title: `${ESCAPE_CATEGORIES[category]} | Weekend Escapes`,
          description:
            'Make your free time count – our carefully-curated guide to the most unique and chic hotels, pubs, bars, restaurants, cafés, cottages and spa breaks across England.',
          image:
            'https://muddystilettos.co.uk/images/weekend-escapes/Leader.jpg',
          url: `https://muddystilettos.co.uk/weekend-escapes/search/${category}`
        }}
      />
      <Header siteId={siteId} siteName={title} />
      <TopBanner ads={adStarCodes} pageType={`weekendescapes`} />
      <Leaderboard adSlot={'TopLB'} sticky />
      <SubLeader>
        <WeekendEscapesLauncher
          selectedCategory={category}
          handleSearchChange={handleSearchChange}
        />
      </SubLeader>
      {error ? (
        <>Problem loading escapes</>
      ) : (
        <BlockResults
          category={ESCAPE_CATEGORIES[category]}
          loading={loading}
          posts={escapes?.nationalEscapes ?? []}
          type={`weekend-escapes`}
          searchTerm={searchTerm ?? undefined}
        />
      )}
      <Leaderboard adSlot={'BottomLB'} />
      <Footer />
    </>
  )
}

export default WeekendEscapesSearch
