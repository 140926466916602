// eslint-disable-next-line node/no-extraneous-import
import { gql } from '@apollo/client'
import {
  AdStarBlock,
  GeneralSettings,
  NationalWeekendEscape,
  LbbCategory
} from '../../types'

export interface IWeekendEscapesSearchQueryData {
  wpgraphql: {
    adStarCodes: AdStarBlock[]
    generalSettings: GeneralSettings
    lBBCategory: LbbCategory
    promoBoxesGroupSettings: {
      promoBoxes: string
    }
  }
}

export const WeekendEscapesResultsQuery = gql`
  query ($category: String) {
    nationalEscapes(category: $category) {
      id
      title
      uri
      county
      homepagePreviewImage
      description
      facilities
    }
  }
`

export const WeekendEscapesTextQuery = gql`
  query ($search: String) {
    weekendEscapesSearch(search: $search) {
      id
      title
      uri
      county
      homepagePreviewImage
      description
      facilities
    }
  }
`

export interface IWeekendEscapesResultsQueryData {
  nationalEscapes: NationalWeekendEscape[]
}

export interface IWeekendEscapesTextQueryData {
  weekendEscapesSearch: NationalWeekendEscape[]
}
