const POPULAR_SEARCHES = [
  {
    id: '1',
    title: 'Beach Retreat',
    uri: '/weekend-escapes/search/beach-retreat',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage:
      '/images/weekend-escapes/popular-searches/beach-retreat.jpg'
  },
  {
    id: '2',
    title: 'Budget',
    uri: '/weekend-escapes/search/budget',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/popular-searches/budget.jpg'
  },
  {
    id: '3',
    title: 'Cosy Cornwall',
    uri: '/weekend-escapes/search/cosy-cornwall',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage:
      '/images/weekend-escapes/popular-searches/cosy-cornwall.jpg'
  },
  {
    id: '4',
    title: 'Design Led',
    uri: '/weekend-escapes/search/design-led',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage:
      '/images/weekend-escapes/popular-searches/design-led.jpg'
  },
  {
    id: '5',
    title: 'Hampshire Boltholes',
    uri: '/weekend-escapes/search/hampshire-boltholes',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage:
      '/images/weekend-escapes/popular-searches/hampshire-boltholes.jpg'
  },
  {
    id: '6',
    title: 'Happy Kids',
    uri: '/weekend-escapes/search/happy-kids',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage:
      '/images/weekend-escapes/popular-searches/happy-kids.jpg'
  },
  {
    id: '7',
    title: 'Lo-Fi',
    uri: '/weekend-escapes/search/lo-fi',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/popular-searches/lo-fi.jpg'
  },
  {
    id: '7',
    title: 'Norfolk Hideaways',
    uri: '/weekend-escapes/search/norfolk-hideaways',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage:
      '/images/weekend-escapes/popular-searches/norfolk-hideaway.jpg'
  }
]

const COUNTIES = [
  {
    id: '1',
    title: 'Bedfordshire',
    uri: '/weekend-escapes/search/?county=Bedfordshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/bedfordshire.jpg'
  },
  {
    id: '2',
    title: 'Berkshire',
    uri: '/weekend-escapes/search/?county=Berkshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/berkshire.jpg'
  },
  {
    id: '3',
    title: 'Buckinghamshire',
    uri: '/weekend-escapes/search/?county=Buckinghamshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/bucks.jpg'
  },
  {
    id: '4',
    title: 'Cambridgeshire',
    uri: '/weekend-escapes/search/?county=Cambridgeshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/cambs.jpg'
  },
  {
    id: '5',
    title: 'Cornwall',
    uri: '/weekend-escapes/search/?county=Cornwall',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/cornwall.jpg'
  },
  {
    id: '1',
    title: 'Devon',
    uri: '/weekend-escapes/search/?county=Devon',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/devon.jpg'
  },
  {
    id: '1',
    title: 'Derbyshire',
    uri: '/weekend-escapes/search/?county=Derbyshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/derby.jpg'
  },
  {
    id: '1',
    title: 'Dorset',
    uri: '/weekend-escapes/search/?county=Dorset',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/dorset.jpg'
  },
  {
    id: '1',
    title: 'Essex',
    uri: '/weekend-escapes/search/?county=Essex',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/essex.jpg'
  },
  {
    id: '1',
    title: 'Gloucestershire',
    uri: '/weekend-escapes/search/?county=Gloucestershire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/glos.jpg'
  },
  {
    id: '1',
    title: 'Hampshire',
    uri: '/weekend-escapes/search/?county=Hampshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/hants.jpg'
  },
  {
    id: '1',
    title: 'Hertfordshire',
    uri: '/weekend-escapes/search/?county=Hertfordshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/herts.jpg'
  },
  {
    id: '1',
    title: 'Isle of Wight',
    uri: '/weekend-escapes/search/?county=Isle Of Wight',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/hants.jpg'
  },
  {
    id: '1',
    title: 'Kent',
    uri: '/weekend-escapes/search/?county=Kent',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/kent.jpg'
  },
  {
    id: '1',
    title: 'Leicestershire',
    uri: '/weekend-escapes/search/?county=Leicestershire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/leics.jpg'
  },
  {
    id: '1',
    title: 'Nottinghamshire',
    uri: '/weekend-escapes/search/?county=Nottinghamshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/notts.jpg'
  },
  {
    id: '1',
    title: 'Norfolk',
    uri: '/weekend-escapes/search/?county=Norfolk',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/norfolk.jpg'
  },
  {
    id: '1',
    title: 'Northamptonshire',
    uri: '/weekend-escapes/search/?county=Northamptonshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/northants.jpg'
  },
  {
    id: '1',
    title: 'Oxfordshire',
    uri: '/weekend-escapes/search/?county=Oxfordshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/oxford.jpg'
  },
  {
    id: '1',
    title: 'Rutland',
    uri: '/weekend-escapes/search/?county=Rutland',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/oxford.jpg'
  },
  {
    id: '1',
    title: 'Somerset',
    uri: '/weekend-escapes/search/?county=Somerset',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/somerset.jpg'
  },
  {
    id: '1',
    title: 'Suffolk',
    uri: '/weekend-escapes/search/?county=Suffolk',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/suffolk.jpg'
  },
  {
    id: '1',
    title: 'Surrey',
    uri: '/weekend-escapes/search/?county=Surrey',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/surrey.jpg'
  },
  {
    id: '1',
    title: 'Sussex',
    uri: '/weekend-escapes/search/?county=Sussex',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/sussex.jpg'
  },
  {
    id: '1',
    title: 'Warwickshire',
    uri: '/weekend-escapes/search/?county=Warwickshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/warwick.jpg'
  },
  {
    id: '1',
    title: 'West Midlands',
    uri: '/weekend-escapes/search/?county=West Midlands',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/west-midlands.jpg'
  },
  {
    id: '1',
    title: 'Wiltshire',
    uri: '/weekend-escapes/search/?county=Wiltshire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/wilts.jpg'
  },
  {
    id: '1',
    title: 'Worcestershire',
    uri: '/weekend-escapes/search/?county=Worcestershire',
    linkType: 'internal',
    primaryCategory: null,
    primaryCategoryURI: null,
    homepagePreviewImage: '/images/weekend-escapes/counties/worcs.jpg'
  }
]

const CATEGORIES = [
  {
    slug: 'coastal',
    name: 'Coastal'
  },
  {
    slug: 'day-trip',
    name: 'Day Trip'
  },
  {
    slug: 'pet-friendly',
    name: 'Pet Friendly'
  },
  {
    slug: 'spas',
    name: 'Spas'
  },
  {
    slug: 'countryside',
    name: 'Countryside'
  },
  {
    slug: 'family',
    name: 'Family'
  },
  {
    slug: 'waterside',
    name: 'Waterside'
  },
  {
    slug: 'special-occasion',
    name: 'Special Occasions'
  },
  {
    slug: 'foodie',
    name: 'Foodie'
  },
  {
    slug: 'city',
    name: 'City'
  },
  {
    slug: 'romantic',
    name: 'Romantic'
  },
  {
    slug: 'experiences',
    name: 'Experiences'
  }
]

export { POPULAR_SEARCHES, COUNTIES, CATEGORIES }
