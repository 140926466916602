import React, { useEffect, useState } from 'react'
// eslint-disable-next-line node/no-missing-import
import { navigate } from '@reach/router'
import { CATEGORIES } from '../../../lib/data/WeekendEscapes'

import * as BTPLStyles from '../../layoutComponents/bptl/BPTL.module.scss'

interface WeekendEscapesLauncherProps {
  handleSearchChange?: (searchTerm: string) => void
  currentValue?: string
  filterOnly?: boolean
  selectedCategory?: string
}

const WeekendEscapesLauncher = ({
  handleSearchChange,
  currentValue,
  selectedCategory
}: WeekendEscapesLauncherProps) => {
  const [searchTerm, setSearchTerm] = useState<string>()
  const [searchButtonValid, setSearchButtonValid] = useState<boolean>(false)
  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(`/weekend-escapes/search/${event.target.value}`)
  }

  const handleInputChange = () => {
    handleSearchChange && handleSearchChange(searchTerm ?? '')
  }

  useEffect(() => {
    if (searchTerm !== currentValue) {
      setSearchButtonValid(true)
    } else {
      setSearchButtonValid(false)
    }
  }, [searchTerm])

  useEffect(() => {
    if (currentValue) setSearchTerm(currentValue)
  }, [currentValue])

  return (
    <>
      <h1>Weekend Escapes</h1>
      <p>
        Make your free time count – our carefully-curated guide to the most
        unique and chic hotels, pubs, bars, restaurants, cafés, cottages and spa
        breaks across England.
      </p>
      <div className={BTPLStyles.Launcher}>
        {handleSearchChange && (
          <>
            <input
              type="search"
              placeholder={`Search for...`}
              defaultValue={currentValue}
              onChange={e => setSearchTerm(e.target.value)}
            />
          </>
        )}
        <select
          name="category"
          defaultValue={selectedCategory ?? ''}
          onChange={handleSelectChange}
        >
          <option value="" disabled>
            ... Or choose a category...
          </option>
          {CATEGORIES.map(({ slug, name: title }, index) => (
            <option key={index} value={slug}>
              {title}
            </option>
          ))}
        </select>

        <button
          disabled={!searchButtonValid}
          className={BTPLStyles.LauncherButton}
          onClick={handleInputChange}
        >
          Search
        </button>
      </div>
    </>
  )
}

export default WeekendEscapesLauncher
